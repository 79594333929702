import request, { MarkcoinRequest } from '@/plugins/request'
import { ITradePairDetailData, ITradePairDetailDataReq } from '@/typings/api/future/common'
import {
  YapiGetV1PerpetualTradePairListApiRequest,
  YapiGetV1PerpetualTradePairListApiResponse,
} from '@/typings/yapi/PerpetualTradePairListV1GetApi'

/**
 * [合约币对详情↗](https://yapi.nbttfc365.com/project/44/interface/api/4047)
 * */
export const getTradePairDetailApi: MarkcoinRequest<ITradePairDetailDataReq, ITradePairDetailData> = params => {
  return request({
    path: '/v1/perpetual/tradePair/detail',
    method: 'GET',
    params,
  })
}

/**
 * [合约币对列表  ↗](https://yapi.nbttfc365.com/project/44/interface/api/461)
 * */
export const getV1PerpetualTradePairListApiRequest: MarkcoinRequest<
  YapiGetV1PerpetualTradePairListApiRequest,
  YapiGetV1PerpetualTradePairListApiResponse['data']
> = params => {
  return request({
    path: '/v1/perpetual/tradePair/list',
    method: 'GET',
    params,
  })
}
