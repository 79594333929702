import request, { MarkcoinRequest } from '@/plugins/request'
import {
  YapiGetV1TradePairDetailApiRequest,
  YapiGetV1TradePairDetailApiResponse,
} from '@/typings/yapi/TradePairDetailV1GetApi'

/**
 * 24 小时行情
 */
export const getMarketTicker: MarkcoinRequest<
  YapiGetV1TradePairDetailApiRequest,
  YapiGetV1TradePairDetailApiResponse['data']
> = params => {
  return request({
    path: `/v1/tradePair/detail`,
    method: 'GET',
    params,
  })
}
